/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const FindHDynamicImage = ({ imgName, imgNameSmall, className = '' }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 1280, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                originalName
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const imageBig = data.allImageSharp.edges.find(
        (edge) => edge.node.fluid.originalName === imgName
      )
      const imageSmall = data.allImageSharp.edges.find(
        (edge) => edge.node.fluid.originalName === imgNameSmall
      )

      const sources = [
        imageBig.node.fluid,
        {
          ...imageSmall.node.fluid,
          media: `(max-width: 768px)`,
        },
      ]

      if (!sources) {
        return null
      }
      return <Img fluid={sources} className={`${className}`} />
    }}
  />
)

FindHDynamicImage.propTypes = {
  imgName: PropTypes.node.isRequired,
  imgNameSmall: PropTypes.node.isRequired,
}

export default FindHDynamicImage
