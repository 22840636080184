import * as React from 'react'
import { Layout } from '../components/layout'
import SEO from '../components/SEO/seov2'
import FindImage from '../components/findImage'
import { LogoCloud } from '../components/logocloud'
import { Forminio } from '../components/forminio2'
import { ProcessChart } from '../components/processlist'
import { Testimonial } from '../components/testimonialv2'
import { HeroHeaderV3 } from '../components/heroheader'
import { FeatureListV3 } from '../components/featurelistv3'
import { IconName, Icon } from '../icons/icon'
import { WizzardSteps } from '../components/wizzardsteps'
import { Button, ButtonStyles, ButtonSizes } from '../components/elements/button'
import { Link } from 'gatsby'
import FindHDynamicImage from '../components/findDynamicImage'

const FeatureBeschreibung: Array<{
  id: number
  iconName: IconName
  title: string
  paragraph: string
}> = [
  {
    id: 1,
    iconName: 'fashion',
    title: 'funktional & individuell',
    paragraph:
      'Wir bieten Ihnen Arbeitskleidung, die nicht nur funktional und bequem, sondern auch modisch ist.',
  },
  {
    id: 2,
    iconName: 'delivery',
    title: 'Kurze Lieferzeiten',
    paragraph:
      'Die Kleidung können wir innerhalb weniger Tage liefern und dies sogar mit individueller Textilveredelung wie Stick & Druck. Sie benötigen kein eigenes Bekleidungslager, denn sie können einzelne Kleidungsstücke jederzeit nachbestellen.',
  },
  {
    id: 3,
    iconName: 'find',
    title: 'Grosser Showroom',
    paragraph:
      'Wir verfügen über einen der grössten Showrooms der Ostschweiz. Weiter greifen wir auf ein Sortiment von über 80‘000 Artikeln zurück. So sind wir in der Lage, für Sie ein Bekleidungskonzept zu erstellen, welches das Image und die Werte Ihrer Firma unterstreicht.',
  },
]

interface CTAProps {
  className?: string
}

const CTA: React.FC<CTAProps> = ({ className }) => (
  <div className={` flex justify-center ${className}`}>
    <Link to="/wizzard/">
      <Button
        buttonstyle={ButtonStyles.default}
        size={ButtonSizes.default}
        className="bg-goldig-500 border-none hover:bg-goldig-200">
        Mehr Erfahren
        <Icon name="arrow-right" className="ml-4 h-8 w-8" />
      </Button>
    </Link>
  </div>
)

const Landingpage1: React.FC = () => {
  return (
    <Layout>
      <SEO
        pagetitle="Firmenbekleidung für Gewerbe und Handwarksbetriebe"
        description="Kein Lager notwendig | Kurzer Lieferfristen und jederzeit nachlieferbar | grösstes Sortiment der Ostschweiz mit über 80'000 Produkten"
      />
      <HeroHeaderV3
        imgName="titelbild.jpg"
        title="Suchen Sie die perfekte Arbeitsbekleidung?"
        paragraph="Als Workwear-Spezialist beraten wir 
        Bau- und Handwerkbetriebe auf der Suche nach der optimalen Arbeitsbekleidung."
      />
      <FeatureListV3 title="Unsere Vorteile" features={FeatureBeschreibung} />
      <CTA />
      <div className="section p-sm-fluid">
        <FindHDynamicImage
          imgName="showroom-2-1 short.jpg"
          imgNameSmall="showroom-2-1 short_mobil.jpg"
        />
      </div>
      <div id="start-form" className="bg-goldig-500">
        <div className="section p-sm-fluid max-w-3xl">
          <WizzardSteps />
        </div>
      </div>
      <ProcessChart />
      <CTA />
      <div className="section p-sm-fluid">
        <FindHDynamicImage
          imgName="showroom-1-1 short.jpg"
          imgNameSmall="showroom-1-1 short_mobil.jpg"
        />
      </div>
      <LogoCloud
        title="Unser Sortiment"
        paragraph="Wir verfügen über eine grosse Anzahl führender Marken im Handwerk- und Baubereich. Für Sie
      finden wir unkompliziert die beste Lösung zu einem starken Preis- Leistungs-Verhältnis."
      />
      <div className="section p-sm-fluid">
        <div className="grid lg:grid-cols-2 items-stretch bg-black">
          <FindImage imgName="kevin.jpg" />
          <div className="items-center p-sm-fluid text-goldig-500">
            <Forminio className="text-goldig-500" labelClassName="text-white" />
          </div>
        </div>
      </div>
      <Testimonial />
      <CTA className="py-12" />
    </Layout>
  )
}

export default Landingpage1
