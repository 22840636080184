import * as React from 'react'
import { Icon, IconName } from '../icons/icon'

interface FeatureItemProps {
  iconName: IconName
  title: string
  paragraph?: string
  children: React.ReactNode
}

export const FeatureItem: React.FC<FeatureItemProps> = ({
  iconName,
  title,
  paragraph,
  children,
}) => (
  <div className="flex bg-white p-4">
    <div className="pr-4">{children}</div>
    <div className="mx-auto flex flex-col items-left justify-top">
      <div className="visible md:invisible pb-4">
        <svg width="244" height="24" viewBox="0 0 244 24" fill="none">
          <path d="M0.250085 0.749878L105.75 0.749902L121.75 22.7501" stroke="#C0C0C0" />
          <path d="M243.75 0.749878L138.25 0.749902L122.25 22.7501" stroke="#C0C0C0" />
        </svg>
      </div>
      <Icon name={iconName} className="w-16 h-16" />
      <h2 className="text-xl-rfs leading-6 font-bold py-4">{title}</h2>
      <p className="mt-2 text-base-rfs leading-6">{paragraph} </p>
    </div>
  </div>
)

export const ProcessChart: React.FC = () => (
  <div className="section p-sm-fluid">
    <h2 className="title2 max-w-3xl">Der Weg zu Ihrer neuen Firmenbekleidung</h2>
    <div className="p-b-sm-fluid" />
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-sm-fluid lg:gap-8">
      <FeatureItem
        iconName="special"
        title="Beratungsgespräch"
        paragraph="In einem unverbindlichen Beratungsgespräch klären wir Ihre Bedürfnisse ab und erstellen anschliessend ein „massgeschneidertes“ Angebot, das unterschiedliche Ausführungsvarianten mit möglichen Musterteilen enthält.">
        <div className="w-8 invisible lg:visible" />
      </FeatureItem>
      <FeatureItem
        iconName="delivery1"
        title="Bemusterung &amp; Anprobe"
        paragraph="Nachdem Sie die Musterteile begutachtet und sich für die geeignete Firmenbekleidung entschieden haben, stellen wir Ihnen Grössensätze zur Verfügung oder wir kommen für die Mitarbeiteranprobe bei Ihnen vorbei. Beides ist in unserer umfangreichen Dienstleistung inbegriffen.">
        <div className="w-8 invisible lg:visible">
          <svg width="24" height="244" viewBox="0 0 24 244" fill="none">
            <path d="M1 243.5L1.00002 138L23.0002 122" stroke="#C0C0C0" />
            <path d="M1 -6.66773e-06L1.00002 105.5L23.0002 121.5" stroke="#C0C0C0" />
          </svg>
        </div>
      </FeatureItem>
      <FeatureItem
        iconName="shipping"
        title="Auslieferung &amp; flexible Nachbestellung"
        paragraph="Nach Eingang Ihrer Bestellung liefern wir Ihnen die Arbeitsbekleidung innert kurzer Zeit aus. Wichtig: Bestellen Sie nicht zu viel – Sie erhalten von uns attraktive Nachbestellungskonditionen und können einzelne Artikel jederzeit nachbestellen.">
        <div className="w-8 invisible lg:visible">
          <svg width="24" height="244" viewBox="0 0 24 244" fill="none">
            <path d="M1 243.5L1.00002 138L23.0002 122" stroke="#C0C0C0" />
            <path d="M1 -6.66773e-06L1.00002 105.5L23.0002 121.5" stroke="#C0C0C0" />
          </svg>
        </div>
      </FeatureItem>
    </div>
  </div>
)
