import React from 'react'
import { Helmet } from 'react-helmet'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'
import Facebook from './facebook'
import Twitter from './twitter'
import SchemaOrg from './schemaorg'

const query = graphql`
  query SEO {
    site {
      buildTime(formatString: "YYYY-MM-DD")
      siteMetadata {
        siteUrl
        defaultTitle: title
        defaultDescription: description
        defaultBanner: banner
        titleAlt
        headline
        siteLanguage
        ogLanguage
        author
        twitter
        facebook
      }
    }
  }
`

const SEO = ({ pagetitle, description, banner, pathname, article, firstPublicationDate }) => {
  const { site } = useStaticQuery(query)

  const {
    buildTime,
    siteMetadata: {
      siteUrl,
      defaultTitle,
      defaultDescription,
      defaultBanner,
      titleAlt,
      headline,
      siteLanguage,
      ogLanguage,
      author,
      twitter,
      facebook,
    },
  } = site

  const seo = {
    title: `${pagetitle} | ${defaultTitle}` || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${banner || defaultBanner}`,
    url: `${siteUrl}${pathname || ''}`,
  }

  return (
    <>
      <Helmet title={seo.title}>
        <html lang={siteLanguage} />
        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
      </Helmet>
      <SchemaOrg
        url={seo.url}
        description={seo.description}
        image={seo.image}
        siteUrl={siteUrl}
        headline={headline}
        siteLanguage={siteLanguage}
        defaultDescription={defaultDescription}
        defaultTitle={defaultTitle}
        title={seo.title}
        titleAlt={titleAlt}
        author={author}
        buildTime={buildTime}
        defaultBanner={defaultBanner}
        // node={node}
        article={article}
        firstPublicationDate={firstPublicationDate}
      />
      <Facebook
        description={seo.description}
        image={seo.image}
        title={seo.title}
        type={article ? 'article' : 'website'}
        url={seo.url}
        locale={ogLanguage}
        name={facebook}
      />
      <Twitter
        title={seo.title}
        image={seo.image}
        description={seo.description}
        username={twitter}
      />
    </>
  )
}

export default SEO

SEO.propTypes = {
  pagetitle: PropTypes.string,
  description: PropTypes.string,
  banner: PropTypes.string,
  pathname: PropTypes.string,
  article: PropTypes.bool,
  // node: PropTypes.node,
  firstPublicationDate: PropTypes.string,
}

SEO.defaultProps = {
  pagetitle: null,
  description: null,
  banner: null,
  pathname: null,
  article: false,
  // node: null,
  firstPublicationDate: null,
}
