import * as React from 'react'
import { Icon, IconName } from '../icons/icon'

interface FeatureItemProps {
  iconName: IconName
  title: string
  paragraph?: string
}

export const FeatureItem = ({ iconName, title, paragraph }: FeatureItemProps) => (
  <div className="mt-10 lg:mt-0">
    <h5 className="text-xl-rfs leading-6 font-bold">{title}</h5>
    <div className="w-24 my-4 border-t-2 border-black"></div>
    <p className="mt-2 text-base-rfs leading-6">{paragraph}</p>
  </div>
)

type FeatureListV3Props = {
  title: string
  features: {
    id: number
    iconName: IconName
    title: string
    paragraph: string
  }[]
}

export const FeatureListV3 = ({ title, features }: FeatureListV3Props) => (
  <div className="section p-sm-fluid">
    <div className="p-b-sm-fluid">
      <h2 className="title2">{title}</h2>
    </div>
    <div className="lg:grid lg:grid-cols-3 lg:gap-8">
      {features.map((features) => {
        return (
          <FeatureItem
            key={features.id}
            iconName={features.iconName}
            title={features.title}
            paragraph={features.paragraph}
          />
        )
      })}
    </div>
  </div>
)
