import * as React from 'react'
import FindImage from '../components/findImage'

interface LogoBoxProps {
  children: React.ReactNode
}

export const LogoBox = ({ children }: LogoBoxProps) => (
  <div className="col-span-1 flex justify-center items-center py-8 px-8">{children}</div>
)

const logolist = [
  {
    id: 1,
    imgName: 'hakro.jpg',
    className: 'w-24',
  },
  {
    id: 2,
    imgName: 'james-nicholson.jpg',
    className: 'w-32',
  },
  {
    id: 3,
    imgName: 'engel-workwear.png',
    className: 'w-24',
  },
  {
    id: 4,
    imgName: 'wikland.png',
    className: 'w-24',
  },
  {
    id: 5,
    imgName: 'macseis.png',
    className: 'w-24',
  },
  {
    id: 6,
    imgName: 'projob-workwear.png',
    className: 'w-24',
  },
  {
    id: 6,
    imgName: 'dassy.jpg',
    className: 'w-24',
  },
]

export const LogoGroup2 = () => (
  <div className="mt-8 grid grid-cols-2 gap-0.5 md:grid-cols-4 lg:mt-0 lg:grid-cols-8">
    {logolist.map((logo) => {
      return (
        <LogoBox key={logo.id}>
          <FindImage imgName={logo.imgName} className={logo.className} />
        </LogoBox>
      )
    })}
    <LogoBox>
      <p className="text-2xl-rfs font-bold font-heading">+ viele weitere...</p>
    </LogoBox>
  </div>
)

interface LogoCloudProps {
  title: string
  paragraph: string
}

export const LogoCloud = ({ title, paragraph }: LogoCloudProps) => (
  <div className="bg-gray-50">
    <div className="section p-sm-fluid">
      <div className=" flex flex-col space-y-8">
        <h2 className="title2">{title}</h2>
        <p className="subtitle max-w-3xl">{paragraph}</p>
        <LogoGroup2 />
      </div>
    </div>
  </div>
)
