import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import Img from 'gatsby-image'

const FindImage = ({ imgName, className = '' }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(maxWidth: 1280, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
                originalName
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.allImageSharp.edges.find(
        (edge) => edge.node.fluid.originalName === imgName
      )
      if (!image) {
        return null
      }
      return <Img fluid={image.node.fluid} className={`${className}`} />
    }}
  />
)

FindImage.propTypes = {
  imgName: PropTypes.node.isRequired,
  className: PropTypes.string,
}

FindImage.defaultProps = {
  className: null,
}

export default FindImage
