import * as React from 'react'
import FindBackgroundImage from '../components/findBackgroundImage'
import { Icon } from '../icons/icon'

interface HeroHeaderV2Props {
  imgName: string
  title: string
  paragraph: string
}

export const HeroHeaderV2 = ({ imgName, title, paragraph }: HeroHeaderV2Props) => (
  <div className="p-l-sm-fluid p-r-sm-fluid">
    <div className="flex items-center justify-center py-8 ">
      <Icon name="logo" className="text-black" />
    </div>
    <FindBackgroundImage imgName={imgName} className=" relative h-96">
      <div className="p-sm-fluid">
        <div className="max-w-3xl">
          <h1 className="absolute bottom-0 tracking-normal text-white text-4xl-rfs leading-none font-bold font-heading">
            {title}
          </h1>
        </div>
      </div>
    </FindBackgroundImage>
    <div className="p-sm-fluid bg-goldig-500">
      <h3 className="text-white text-2xl-rfs">{paragraph}</h3>
    </div>
  </div>
)

export const HeroHeaderV3 = ({ imgName, title, paragraph }: HeroHeaderV2Props) => (
  <div className="p-l-sm-fluid p-r-sm-fluid">
    <div>
      <div className="flex items-center justify-center py-8 ">
        <Icon name="logo" className="text-black" />
      </div>
      <div>
        <FindBackgroundImage imgName={imgName}>
          <div className="h-72" />
          <div className="p-xs-fluid items-stretch ">
            <div className="p-8  bg-goldig-500 max-w-lg">
              <h1 className=" text-white tracking-normal text-3xl-rfs leading-snug font-bold font-heading">
                {title}
              </h1>
              <div className="my-4" />
              <h3 className="text-1xl-rfs ">{paragraph}</h3>
            </div>
          </div>
        </FindBackgroundImage>
      </div>
    </div>
  </div>
)
