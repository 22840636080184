import * as React from 'react'
import FindImage from './findImage'

export const Testimonial: React.FC = () => (
  <div className="bg-gray-50">
    <div className="section p-sm-fluid">
      <h3 className="text-2xl-rfs text-black">Referenz</h3>
      <div className="mb-4-rfs" />
      <h2 className="title2 max-w-3xl">Hörler Tiefbau aus Appenzell</h2>
      <div className="p-b-sm-fluid" />
      <div className="bg-white shadow-2xl rounded-lg  max-w-xl">
        <div className="px-4 py-5 sm:p-6">
          <p className="subtitle text-gray-900">
            Wir entwickelten für die Hörler Tiefbau AG eine saisonale Bekleidung für die
            Mitarbeitenden auf dem Bau, die Projektleiter sowie die Geschäftsleitung. Diese umfasst
            unter anderem Jacken sowie lange und kurze Hosen mit Warnschutz, bequeme T-Shirts,
            Pullover und Fleece-Jacken im Gelb ihres Logos. Viele der Mitarbeiter tragen ihr Handy
            auf der rechten Seite. Wir fertigen deshalb die Arbeitshosen mit Handytasche am rechten
            Bein. Dazu versehen wir alle Kleider mit dem Namen des Mitarbeitenden.
          </p>
          <div className="pb-5" />
          <div className="subtitle text-gray-500">Oliver und Kyril Hörler, Niederteufen AR</div>
        </div>
      </div>
      <div className="p-b-sm-fluid" />
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-8">
        <FindImage imgName="hoerler4.jpg" />
        <FindImage imgName="hoerler2.jpg" />
        <FindImage imgName="hoerler3.jpg" />
        <FindImage imgName="hoerler1.jpg" />
      </div>
    </div>
  </div>
)
