import * as React from 'react'
// import { Button, ButtonSizes, ButtonStyles } from './elements/button'
import { Form } from 'react-final-form'
import { Field } from 'react-final-form-html5-validation'
import { Button, ButtonStyles, ButtonSizes } from './elements/button'

declare global {
  interface Window {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    dataLayer: any
  }
}

const firstInput = async () => {
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'start_registration',
  })
}

const encode = (data) => {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]))
    .join('&')
}

const onSubmit = async (values) => {
  fetch('/?no-cache=1', {
    method: 'POST',
    headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
    body: encode({
      'form-name': 'contact',
      ...values,
    }),
  }).then(() => {
    alert('Danke für die Anfrage. Wir werden uns sofort bei Ihnen melden.')
  })
  window.dataLayer = window.dataLayer || []
  window.dataLayer.push({
    event: 'complete_registration',
  })
}

interface Values {
  firstName?: string
  lastName?: string
  region?: string
  mitarbeiter?: string
}

interface FormProps {
  className?: string
  labelClassName?: string
}

export const Forminio: React.FC<FormProps> = ({ className, labelClassName }) => {
  return (
    <Form
      onSubmit={onSubmit}
      initialValues={{ region: '#empty', mitarbeiter: '#empty' }}
      render={({ handleSubmit }) => (
        <form
          onSubmit={handleSubmit}
          className={className}
          name="contact"
          method="POST"
          data-netlify="true"
          data-netlify-honeypot="bot-field">
          <input type="hidden" name="form-name" value="contact" />
          <div>
            <h3 className={`text-2xl-rfs font-bold font-heading pb-4 ${labelClassName}`}>
              Jetzt kostenloses Beratungsgepräch vereinbaren
            </h3>
            <p className="block text-sm font-medium leading-5 pb-2">Anfrage starten</p>
          </div>
          <div>
            <Field
              className="text-gray-500 form-select bg-white relative block w-full rounded-none rounded-t-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              name="region"
              component="select"
              onFocus={firstInput}
              required
              // onChange={firstInput}
              validate={(value) => (value === '#empty' ? 'Bitte Region auswählen' : undefined)}>
              <option value="#empty"> Region auswählen </option>
              <option value="#ostschweiz">Ostschweiz</option>
              <option value="#zentralschweiz">Zentralschweiz</option>
              <option value="#graubünden">Graubünden</option>
              <option value="#genferseeregion">Genferseeregion</option>
              <option value="#mittelland">Mittelland</option>
              <option value="#tessin">Tessin</option>
              <option value="#rest">Rest der Schweiz</option>
            </Field>
          </div>
          <div className="-mt-px">
            <Field
              className="text-gray-500 form-select bg-white relative block w-full rounded-none bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              name="mitarbeiter"
              component="select"
              required
              validate={(value) =>
                value === '#empty' ? 'Bitte Anzahl einzukleidende Personen auswählen' : undefined
              }>
              <option value="#empty"> Anzahl einzukleidene Personen </option>
              <option value="#1bis5">1-5 Personen</option>
              <option value="#5bis20">5-20 Personen</option>
              <option value="#20bis50">20-50 Personen</option>
              <option value="#50bis200">50-200 Personen</option>
            </Field>
          </div>
          <div className="-mt-px">
            <Field
              className="text-gray-500 form-input bg-white relative block w-full rounded-none bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              name="firstName"
              component="input"
              type="text"
              placeholder="Name angeben"
              required
              minLength={3}
              tooShort=">3 Buchstaben notwendig"
            />
          </div>
          <div className="-mt-px">
            <Field
              className="text-gray-500 form-input bg-white relative block w-full rounded-none rounded-b-md bg-transparent focus:z-10 transition ease-in-out duration-150 sm:text-sm sm:leading-5"
              name="email"
              component="input"
              type="email"
              placeholder="Email angeben"
              typeMismatch="Leider ist dies keine gültige Mailadresse"
              required
              valueMissing="Für den unverbindlichen Kontakt bräuchten wir eine Mail"
            />
            {/* {({ input, meta: { error, touched }, ...rest }) => (
                <div>
                  <input {...input} {...rest} />
                  {error && touched && <span>{error}</span>}
                </div>
              )} */}
            {/* </Field> */}
          </div>
          <div className="pt-4">
            <Button buttonstyle={ButtonStyles.default} size={ButtonSizes.xs}>
              Anfragen senden
            </Button>
          </div>
        </form>
      )}
    />
  )
}
