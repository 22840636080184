import React from 'react'
import PropTypes from 'prop-types'
import { StaticQuery, graphql } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'

//

const FindImage = ({ imgName, children, className = '' }) => (
  <StaticQuery
    query={graphql`
      query {
        allImageSharp {
          edges {
            node {
              fluid(quality: 100, maxWidth: 1280) {
                ...GatsbyImageSharpFluid_withWebp_tracedSVG

                originalName
                base64
                aspectRatio
                src
                srcSet
                srcWebp
                srcSetWebp
                sizes
              }
            }
          }
        }
      }
    `}
    render={(data) => {
      const image = data.allImageSharp.edges.find(
        (edge) => edge.node.fluid.originalName === imgName
      )
      if (!image) {
        return null
      }
      return (
        <BackgroundImage Tag="section" fluid={image.node.fluid} className={`${className}`}>
          {children}
        </BackgroundImage>
      )
    }}
  />
)

FindImage.propTypes = {
  imgName: PropTypes.node.isRequired,
  className: PropTypes.string,
}

FindImage.defaultProps = {
  className: null,
  children: PropTypes.string,
}

export default FindImage
